import Ably from "ably";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useDrag } from "@use-gesture/react";
import useMeasure from "react-use-measure";

function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num;
}
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
const INTERVAL = 10;

export default function Scroller() {
  const scrolled = useRef(0);
  let [heights, setHeights] = useState();

  const [channel] = useChannel("scroll", () => {});

  const ref = useRef();

  var delay = 30;
  useChannel("height", (message) => {
    setHeights(message.data.maxheights);
  });
  // var handlerFunction = debounce(function (e) {
  //   channel.publish("scroll", { e });
  // }, delay);
  const clickOnceShuffle = (e) => {
    channel.publish("scroll", { e });
  };
  const handlerFunction = useCallback(
    debounce(
      (scrolled) => {
        clickOnceShuffle(scrolled);
      },
      INTERVAL,
      { leading: true, trailing: false, maxWait: INTERVAL }
    ),
    []
  );
  const [height, setHeight] = useState();
  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [ref]);
  const indicator = useRef();
  useDrag(
    (state) => {
      scrolled.current -= state.velocity[1] * state.direction[1] * 100;
      scrolled.current = clamp(scrolled.current, 0, heights);
      indicator.current.style.top =
        ((100 * scrolled.current) / heights) * (height / 100) + "px";
      handlerFunction(scrolled.current);
    },
    { target: ref }
  );
  return (
    <>
      <div ref={ref} className="scroller">
        <div
          ref={indicator}
          style={{
            position: "absolute",
            width: "100%",
            borderTop: "1px solid white",
            top: scrolled.current + "px",
            left: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 20,
            fontSize: 26,
            pointerEvents: "none",
          }}
          className="scrollerindi"
        >
          ←← Scroll →→
        </div>
      </div>
    </>
  );
}
