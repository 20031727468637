import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { useCallback, useEffect, useState, useRef } from "react";
import debounce from "lodash.debounce";
const INTERVAL = 1000;
export default function Shuffle({ ResetTags }) {
  const shuffle = 0;
  const [shufflechannel] = useChannel("shuffle", () => {});
  const debouncedShuffle = useCallback(
    debounce(
      (shuffle) => shufflechannel.publish("shuffle", { shuffle }),
      INTERVAL,
      { leading: true, trailing: false, maxWait: INTERVAL }
    ),
    []
  );

  const [showTags, setShowTags] = useState(false);
  const [tagschannel] = useChannel("showtags", () => {});
  const debouncedTags = useCallback(
    debounce(
      (showTags) => {
        // clickOnceTags(showTags);
        setShowTags(!showTags);
      },
      INTERVAL,
      { leading: true, trailing: false, maxWait: INTERVAL }
    ),
    []
  );

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    shufflechannel.publish("shuffle", { shuffle });
  }, [shuffle]);

  const isFirstRunTags = useRef(true);
  useEffect(() => {
    if (isFirstRunTags.current) {
      isFirstRunTags.current = false;
      return;
    }
    tagschannel.publish("showtags", { showTags });
  }, [showTags]);
  return (
    <div className="shuffle">
      <div onClick={() => debouncedShuffle(shuffle)}>Shuffle</div>
      <div
        onClick={() => debouncedTags(showTags)}
        style={
          {
            // color: showTags ? "rgb(59, 130, 246)" : "white",
            // border: showTags ? "1px solid rgb(59, 130, 246)" : "1px solid white",
          }
        }
      >
        Show tags
      </div>
      <div onClick={ResetTags}>Reset tags</div>
    </div>
  );
}
