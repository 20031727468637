import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { Suspense, useCallback, useEffect, useState, useRef } from "react";
import Ably from "ably";

export default function Columns() {
  const [columns, setColumns] = useState(6);
  const isFirstRun = useRef(true);

  const [channel] = useChannel("test", () => {});
  const increaseColumns = useCallback(() => {
    if (columns < 12) {
      setColumns(columns + 2);
    }
  }, [columns]);
  const decreaseColumns = useCallback(() => {
    if (columns > 1) {
      setColumns(columns - 2);
    }
  }, [columns]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    channel.publish("columns", { columns });
  }, [columns]);
  return (
    <div class="columns">
      <div onClick={increaseColumns}>Increase columns</div>
      <div onClick={decreaseColumns}>Decrease columns</div>
    </div>
  );
}
