import "./styles.css";
import Ably from "ably";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useDrag } from "@use-gesture/react";

import Tags from "./Tags";
import Columns from "./Columns";
import Shuffle from "./Shuffle";
import Scroller from "./Scroller";

function Connector() {
  return (
    <div className="connectorcontainer">
      <div className="connector">Connecting</div>
    </div>
  );
}
export default function App() {
  configureAbly({
    key: "KJVNsA.WP4uRQ:zZu1JFd3YzLCkfkDIBVRncJu36fEcywMhpWBA_d23E4",
    clientId: "09sfjoajlfdkj",
  });

  const [selectedprojects, setSelectedprojects] = useState([]);
  const [selectedcities, setSelectedcities] = useState([]);
  const [selectedarchitects, setSelectedarchitects] = useState([]);
  const [selectedtypologies, setSelectedtypologies] = useState([]);
  const [selectedtags, setSelectedtags] = useState([]);

  function ResetTags() {
    if (
      selectedprojects.length !== 0 ||
      selectedcities.length !== 0 ||
      selectedarchitects.length !== 0 ||
      selectedtypologies.length !== 0 ||
      selectedtags.length !== 0
    ) {
      setSelectedprojects([]);
      setSelectedarchitects([]);
      setSelectedtypologies([]);
      setSelectedtags([]);
      setSelectedcities([]);
    }
  }

  const [projectchannel] = useChannel("project", () => {});
  const [citychannel] = useChannel("city", () => {});
  const [architectchannel] = useChannel("architect", () => {});
  const [typologychannel] = useChannel("typology", () => {});
  const [tagschannel] = useChannel("tag", () => {});

  const isFirstRunProject = useRef(true);
  const isFirstRunCity = useRef(true);
  const isFirstRunArchitect = useRef(true);
  const isFirstRunTypology = useRef(true);
  const isFirstRunTag = useRef(true);

  console.log("render", selectedprojects);
  useChannel("selectedprojects", (message) => {
    console.log(message);
    setSelectedprojects(message);
  });
  useEffect(() => {
    if (isFirstRunProject.current) {
      isFirstRunProject.current = false;
      return;
    }
    console.log("render");
    projectchannel.publish("selectedprojects", { selectedprojects });
  }, [selectedprojects]);
  useEffect(() => {
    if (isFirstRunCity.current) {
      isFirstRunCity.current = false;
      return;
    }
    citychannel.publish("selectedcities", { selectedcities });
  }, [selectedcities]);
  useEffect(() => {
    if (isFirstRunArchitect.current) {
      isFirstRunArchitect.current = false;
      return;
    }
    architectchannel.publish("selectedarchitects", { selectedarchitects });
  }, [selectedarchitects]);
  useEffect(() => {
    if (isFirstRunTypology.current) {
      isFirstRunTypology.current = false;
      return;
    }
    typologychannel.publish("selectedtypologies", { selectedtypologies });
  }, [selectedtypologies]);
  useEffect(() => {
    if (isFirstRunTag.current) {
      isFirstRunTag.current = false;
      return;
    }
    tagschannel.publish("selectedtags", { selectedtags });
  }, [selectedtags]);

  return (
    <div className="App">
      <Suspense fallback={<Connector />}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "80vw 20vw",
            height: "100%",
          }}
        >
          <div>
            <Columns />
            <Shuffle ResetTags={ResetTags} />
            <Tags
              selectedprojects={selectedprojects}
              setSelectedprojects={setSelectedprojects}
              projectchannel={projectchannel}
              selectedcities={selectedcities}
              setSelectedcities={setSelectedcities}
              citychannel={citychannel}
              selectedarchitects={selectedarchitects}
              setSelectedarchitects={setSelectedarchitects}
              architectchannel={architectchannel}
              selectedtypologies={selectedtypologies}
              setSelectedtypologies={setSelectedtypologies}
              typologychannel={typologychannel}
              selectedtags={selectedtags}
              setSelectedtags={setSelectedtags}
              tagschannel={tagschannel}
            />
            <div class="contact">
              <div>© Unprofessional Studio 2022</div>
              <div>
                Main Studio:{" "}
                <a href="https://unprofessional.studio">
                  unprofessional.studio
                </a>
              </div>
              <div>
                Travel:{" "}
                <a href="https://travel.unprofessional.studio">
                  travel.unprofessional.studio
                </a>
              </div>
              <div>
                Instagram:{" "}
                <a href="https://instagram.com/unprofessional.studio/">
                  unprofessional.studio
                </a>
              </div>
              <div>
                Design and development:{" "}
                <a href="https://napoleon.services">napoleon.services</a>
              </div>
            </div>
          </div>
          <Scroller />
        </div>
      </Suspense>
    </div>
  );
}
