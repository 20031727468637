import { useEffect, useState } from "react";
import { a, useSpring } from "@react-spring/web";

import useMeasure from "react-use-measure";

export default function Item({ title, item, selecteditem, setselecteditem }) {
  const [open, setOpen] = useState(false);

  const [ref, { height }] = useMeasure();
  const props = useSpring({ height: open ? height : 0, margin: open ? 12 : 0 });
  return (
    <>
      <div
        style={{
          padding: 12,
          backgroundColor: open ? "#fde68a" : "#27272A",
          color: open ? "black" : "white",
          borderBottom: open ? "1px solid white" : "0px solid white",
        }}
        className="bigbutton"
        onClick={() => setOpen(!open)}
      >
        {title} {selecteditem.length !== 0 && <>({selecteditem.length})</>}
      </div>
      <a.div style={{ ...props }} className="heighttransition">
        <div ref={ref}>
          {item
            .sort(function (a, b) {
              if (a.toLowerCase() < b.toLowerCase()) return -1;
              if (a.toLowerCase() > b.toLowerCase()) return 1;
              return 0;
            })
            .map((project, i) => {
              return (
                project && (
                  <div
                    style={{
                      display: "inline-block",
                      color: selecteditem.includes(project)
                        ? "rgb(59, 130, 246)"
                        : "rgb(184, 184, 184)",
                      border: selecteditem.includes(project)
                        ? "1px solid rgb(59, 130, 246)"
                        : "1px solid rgb(184, 184, 184)",
                      margin: 2,
                      padding: "6px 8px",
                      lineHeight: "12px",
                      fontSize: 20,
                      borderRadius: 14,
                    }}
                    onClick={() => {
                      if (selecteditem.includes(project)) {
                        console.log("already there");
                        setselecteditem((prevState) =>
                          prevState.filter((prevItem) => prevItem !== project)
                        );
                      } else {
                        setselecteditem((selected) => [...selected, project]);
                      }
                    }}
                    key={i}
                  >
                    {project}
                  </div>
                )
              );
            })}
        </div>
      </a.div>
    </>
  );
}
