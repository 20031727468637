import { suspend } from "suspend-react";
import { useEffect, useState, useRef } from "react";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import Item from "./Item";

export default function Tags({
  selectedprojects,
  setSelectedprojects,
  projectchannel,
  selectedcities,
  setSelectedcities,
  citychannel,
  selectedarchitects,
  setSelectedarchitects,
  architectchannel,
  selectedtypologies,
  setSelectedtypologies,
  typologychannel,
  selectedtags,
  setSelectedtags,
  tagschannel,
}) {
  const data = suspend(async () => {
    const res = await fetch(
      `https://unprofessional.studio/backend/datatravel.json`
    );
    return await res.json();
  }, []);

  const [projects, setProjects] = useState([]);
  const [cities, setCities] = useState([]);
  const [architect, setArchitect] = useState([]);
  const [typology, setTypology] = useState([]);
  const [tags, setTags] = useState([]);
  let projectsarray = [];
  let citiesarray = [];
  let typologiesarray = [];
  let architectsarray = [];
  let tagsarray = [];
  useEffect(() => {
    if (data) {
      data.map((item) => {
        // project title as tag
        if (item.title !== undefined) {
          //setProjects((titletags) => [...titletags, item.title]);

          if (!projectsarray.includes(item.title)) {
            projectsarray.push(item.title);
          }
          setProjects(projectsarray);
        }
        if (item.images) {
          item.images.map((image) => {
            image.city.split(", ").map((tag) => {
              if (!citiesarray.includes(tag)) {
                citiesarray.push(tag);
              }
              setCities(citiesarray);
            });
            image.typology.split(", ").map((tag) => {
              if (!typologiesarray.includes(tag)) {
                typologiesarray.push(tag);
              }
              setTypology(typologiesarray);
            });
            image.architect.split(", ").map((tag) => {
              if (!architectsarray.includes(tag)) {
                architectsarray.push(tag);
              }
              setArchitect(architectsarray);
            });
            image.tags.split(", ").map((tag) => {
              if (!tagsarray.includes(tag)) {
                tagsarray.push(tag);
              }
              setTags(tagsarray);
            });
          });
        }
      });
    }
  }, [data]);

  return (
    <>
      <div>
        <div>
          <Item
            title={"Countries"}
            item={projects}
            selecteditem={selectedprojects}
            setselecteditem={setSelectedprojects}
            channel={projectchannel}
            channelname={"selectedprojects"}
          />
        </div>
        <div>
          <Item
            title={"Cities"}
            item={cities}
            selecteditem={selectedcities}
            setselecteditem={setSelectedcities}
            channel={citychannel}
            channelname={"selectedcities"}
          />
        </div>
        <div>
          <Item
            title={"Architect"}
            item={architect}
            selecteditem={selectedarchitects}
            setselecteditem={setSelectedarchitects}
            channel={architectchannel}
            channelname={"selectedarchitects"}
          />
        </div>
        <div>
          <Item
            title={"Typology"}
            item={typology}
            selecteditem={selectedtypologies}
            setselecteditem={setSelectedtypologies}
            channel={typologychannel}
            channelname={"selectedtypologies"}
          />
        </div>
        <div className="borderbottom">
          <Item
            title={"Tags"}
            item={tags}
            selecteditem={selectedtags}
            setselecteditem={setSelectedtags}
            channel={tagschannel}
            channelname={"selectedtags"}
          />
        </div>
      </div>
    </>
  );
}
